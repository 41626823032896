import React from 'react';
import PropTypes from 'prop-types';
// Style and SEO
import { Col, Grid, Row, styled, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { selectCart } from '../WebApp/selectors';
import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoadAsync from '_platform/src/utils/LoadAsync';
import { createStructuredSelector } from 'reselect';
import BgGrey from 'components/CustomComponents/Backgrounds/BgGrey';
import Banner from 'components/CustomComponents/Banners/Banner';
import bannerMobile from './images/BOB-2023-320x280-Header1-mobile-@1x-min.png';
import bannerMobile2x from './images/BOB-2023-640x560-Header1-mobile-@2x-min.png';
import bannerTablet from './images/BOB-2023-Header1-1024x280-tablet-@1x-min.png';
import bannerTablet2x from './images/BOB-2023-Header1-2048x560-tablet-@2x-min.png';
import bannerDesktop from './images/BOB-2023-Header-1@1x-min.png';
import bannerDesktop2x from './images/BOB-2023-Header-1@2x-min.png';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const StatementLoyalty = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementLoyalty" */ '../../components/StatementLoyalty/StatementLoyalty'
  )
);

const StatementStyles = styled.div`
  section:not(:last-of-type) {
    border-bottom: 1px solid ${thd('statementHighlightColor', '#f4bd19')};
    padding-bottom: 1.75rem;
  }

  .rt-th {
    color: #fff;
    background-color: ${thd('primary', '#555')};
  }

  h2 {
    text-align: left;
  }

  .ReactTable .rt-thead .rt-th {
    white-space: pre-line;
    word-wrap: break-word;
  }
`;

const fetchStatements = [
  {
    endpoint: '/Statement/v1/Nafda',
    section: 'loyalty',
  },
];

const HomePage = ({ settings, cart }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <Banner
          pageTitle={<React.Fragment>&nbsp;</React.Fragment>}
          bannerMobile={bannerMobile}
          bannerMobile2x={bannerMobile2x}
          bannerTablet={bannerTablet}
          bannerTablet2x={bannerTablet2x}
          bannerDesktop={bannerDesktop}
          bannerDesktop2x={bannerDesktop2x}
        />
        <BgGrey>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} className="text--center">
                <h1 className="text--primary text--tight">Points tracker</h1>
                <ProgressTracker
                  pointsBalance={
                    cart && cart.pointsBalance ? cart.pointsBalance : 0
                  }
                />
              </Col>
            </Row>
          </Grid>
        </BgGrey>
        <Grid>
          <Row className="spacer--bottom--large">
            <Col>
              <h1 className="text--primary text--center">Statement</h1>
              <StatementStyles>
                <StatementProvider
                  fetch={fetchStatements}
                  render={({
                    statement,
                    onStatementUpdateAccountRequest,
                    clients,
                  }) => {
                    function onAccountAdd(values) {
                      return onStatementUpdateAccountRequest(
                        values,
                        fetchStatements
                      );
                    }

                    return !statement || !statement.data ? (
                      <LoadingAnimation /> // Loader instead of `null`?
                    ) : (
                      <StatementLoyalty
                        onStatementUpdateAccountRequest={onAccountAdd}
                        statement={statement.data.loyalty}
                        clients={clients}
                      />
                    );
                  }}
                />
              </StatementStyles>
            </Col>
          </Row>
        </Grid>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  cart: PropTypes.object,
  settings: PropTypes.object.isRequired,
};

HomePage.defaultProps = {
  cart: undefined,
};

const mapStateToProps = createStructuredSelector({
  cart: selectCart(),
});

const withConnect = connect(mapStateToProps);
export default compose(withSettings, withConnect)(HomePage);
